
import Http from './Http'

export default {
    async getQueuesStats() {
        return await Http.get(`/monitor/queue/stats`)
    },
    async getQueueJobs(version) {
        return await Http.get(`monitor/queue/jobs?version=${version}&all=Y`)
    },
    async getQueueSuccessJobs(version) {
        return await Http.get(`/monitor/queue/success?version=${version}&all=Y`)
    },
    async getQueueFailedJobs(version) {
        return await Http.get(`/monitor/queue/failed?version=${version}&all=Y`)
    },
    async deleteJobFromQueue(id, version) {
        return await Http.delete(`/monitor/queue/${id}?version=${version}`)
    },
    async getTenants() {
        return await Http.get(`/monitor/tenants`)
    },
    async getTenantApplications(tenant) {
        return await Http.get(`/monitor/tenant/application?tenant=${tenant}`)
    },
    async createTenantApplication(data) {
        return await Http.post(`/monitor/application`, data)
    },
    async updateTenantApplication(id, data) {
        return await Http.post(`/monitor/application/${id}`, data, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        })
    },
    async deleteTenantApplication(app_id) {
        return await Http.delete(`/monitor/application/${app_id}`)
    },
    async getApiErrors() {
        return await Http.get(`/monitor/api_errors?limit=1000`)
    },
    async getCronLogs() {
        return await Http.get(`/monitor/api_cron_logs?limit=1000`)
    },
    async getExceptions() {
        return await Http.get(`/monitor/api_exceptions?limit=1000`)
    },
    async getApiAlerts() {
        return await Http.get(`/monitor/api_alerts?limit=1000`)
    },
}